import React, { useState } from "react";
import $ from "jquery";
import { getBackendApiUrl } from "../_helpers/backend.url";
import { Role } from "../_helpers/role";
import { useContext, createContext } from "react";
import keycloak from "./keycloak";
import { KeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";

const keycloakProviderInitConfig = {
  onLoad: "check-sso",
};

export const AuthContext = createContext({
  user: null,
  filed: false,
  loginSso: () => {},
  login: () => {},
  logout: () => {},
  updateUser: () => {},
  createAccountUrlSso: () => "",
  requestBackend: (url, type, data, onSuccess, onFail) => {},
});
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [failed, setFailed] = useState(false);
  const history = useHistory();

  function rememberCurrentUser(user) {
    if (!user["role"]) {
      user["role"] = Role.User;
    }
    setUser(user);
  }

  function getUserFromMoodle(userInfo) {
    const data = {
      username: userInfo.sub,
      firstname: userInfo.given_name,
      middlename: userInfo.middle_name,
      lastname: userInfo.family_name,
      email: userInfo.email,
      roles: userInfo.roles ? userInfo.roles.join(",") : "",
    };
    requestBackend({
      url: `/users/check`,
      method: "POST",
      data: data,
      onSuccess: (user) => {
        rememberCurrentUser(user);
        return user;
      },
      onFail: (_, status, err) => {
        console.error("error on getting user from Moodle", status, err);
        setFailed(true);
      },
    });
  }

  function loginSso() {
    if (!keycloak.authenticated) {
      const url = keycloak.createLoginUrl({
        redirectUri: history.location.href,
      });
      window.location.href = url;
    }
  }

  function logout() {
    setUser(null);
    if (keycloak.authenticated) {
      keycloak.logout();
    }
  }

  async function requestBackend({
    url,
    type = "GET",
    data,
    onSuccess,
    onFail,
    ...props
  }) {
    let headers = props.headers || {};
    if (keycloak.authenticated) {
      headers["Authorization"] = "Bearer " + keycloak.token;
    }
    return $.ajax({
      ...props,
      type: type,
      url: getBackendApiUrl(url),
      data: data,
      headers: headers,
      success: onSuccess,
      error:
        onFail ||
        ((_, status, err) => {
          console.error(
            `Status ${status}`,
            err,
            type,
            getBackendApiUrl(url),
            data
          );
        }),
    });
  }

  function createAccountUrlSso() {
    if (keycloak.authenticated) {
      return keycloak.createAccountUrl();
    }
    return `${keycloak.url}/realms/${keycloak.realm}/account`;
  }

  const onKeycloakEvent = (event, error) => {
    if (keycloak.authenticated && event === "onAuthSuccess") {
      getUserFromMoodle(keycloak.idTokenParsed);
    }
    if (event === "onTokenExpired") {
      keycloak
        .updateToken(5)
        .success((refreshed) => {
          if (refreshed) {
            alert("Token was successfully refreshed");
          } else {
            alert("Token is still valid");
          }
        })
        .error(() => {
          alert("Failed to refresh the token, or the session has expired");
        });
    }
  };

  const onKeycloakTokens = (tokens) => {
    if (keycloak.authenticated) {
      getUserFromMoodle(keycloak.idTokenParsed);
    }
  };

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <AuthContext.Provider
        value={{
          user,
          loginSso,
          logout,
          requestBackend,
          createAccountUrlSso,
        }}
      >
        {children}
      </AuthContext.Provider>
    </KeycloakProvider>
  );
};
