import React from 'react';
import { FormGroup } from 'reactstrap';

export const FilterSelect = ({ label, options, defaultSelectedOption, onFilter }) => {
  const onOptionChange = (e) => {
    onFilter(e.target.value);
  };

  return (
    <FormGroup>
      <label className="text-label" style={{ maxWidth: "110px" }}>{label}</label>
      <select className="form-control form-control-sm" onChange={onOptionChange} value={defaultSelectedOption}>
        <option></option>
        {options && options.map((optionValue, i) => <option key={i}>{optionValue}</option>)};
      </select>
    </FormGroup>
  );
};

export function GetFilterOptions(data, optionName) {
  return data && data
    .map(item => item[optionName])
    .filter((val, i, self) => val && self.indexOf(val) === i);
}