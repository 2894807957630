import React, { useState, useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";
import { Spinner } from "reactstrap";
import { Loading } from "../_components/Loading";
import { SelectFile, updateFile } from "../_components/UploadFile";
import Routes, { MediaRoutes } from "../Routes";
import { useAuthContext } from "../_services/authentication.service";
import { SelectAuthors } from "../_components/SelectAuthors";
import { NotFoundPage } from "../Errors/NotFoundPage";
import { ImagePlugged } from "../_components/ImagePlugged";
import Tooltip from "reactstrap/lib/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

export const EditCourse = (props) => {
  const id = props.match.params.id;
  const [course, setCourse] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const { requestBackend } = useAuthContext();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    requestBackend({
      url: "/users/pauthors",
      onSuccess: (data) => {
        setUsers(data);
      },
    });
  }, [requestBackend]);

  useEffect(() => {
    function populateCourseData(courseId) {
      requestBackend({
        url: `/courses/${courseId}`,
        onSuccess: (data) => {
          setCourse(data);
          setLoading(false);
        },
        onFail: () => {
          setCourse(null);
          setLoading(false);
        },
      });
    }
    setLoading(true);
    populateCourseData(id);
  }, [id, requestBackend]);

  function SubmitCourse(formData, onError) {
    const putData = {
      fullName: formData.fullname,
      onSale: formData.onsale,
      price: formData.price,
      description: formData.description,
      shortDescription: formData.shortdescription,
      authors: formData.authors,
      videoUrl: formData.video,
      section: formData.section,
      speciality: formData.speciality,
      department: formData.department,
    };

    const uploads = [];

    uploads.push(
      updateFile(
        course.presentationUrl,
        formData.presentation,
        (newUrl) => (putData["presentationUrl"] = newUrl),
        requestBackend
      )
    );
    uploads.push(
      updateFile(
        course.presentationPreviewUrl,
        formData.presentationpreview,
        (newUrl) => (putData["presentationPreviewUrl"] = newUrl),
        requestBackend
      )
    );
    uploads.push(
      updateFile(
        course.videoPreviewUrl,
        formData.videopreview,
        (newUrl) => (putData["videoPreviewUrl"] = newUrl),
        requestBackend
      )
    );
    uploads.push(
      updateFile(
        course.imageUrl,
        formData.imageUrl,
        (newUrl) => (putData["imageUrl"] = newUrl),
        requestBackend
      )
    );

    return Promise.all(uploads)
      .then(() => {
        requestBackend({
          url: `/courses/${id}`,
          type: "PUT",
          data: putData,
          onSuccess: () => {
            props.history.push(Routes.getActual("Course", id).route);
          },
          onFail: (_, textStatus, errorThrown) => {
            onError({ status: textStatus, message: errorThrown });
          },
        });
      })
      .catch((err) => onError(err));
  }

  if (loading) {
    return <Loading />;
  }

  if (!course) {
    return <NotFoundPage />;
  }

  const thisRoute = Routes.getActual("EditCourse", course.id, course.fullName);

  return (
    <>
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>
      <Formik
        initialValues={{
          fullname: course.fullName,
          onsale: course.onSale,
          price: course.price,
          description: course.description,
          shortdescription: course.shortDescription,
          imageUrl: course.imageUrl,
          authors: course.authors,
          presentation: course.presentationUrl,
          presentationpreview: course.presentationPreviewUrl,
          video: course.videoUrl,
          videopreview: course.videoPreviewUrl,
          section: course.section,
          speciality: course.speciality,
          department: course.department,
          general: "",
        }}
        validationSchema={Yup.object().shape({
          fullname: Yup.string().required(
            "Название лекции не может быть пустым"
          ),
          price: Yup.number()
            .required("Укажите цену лекции")
            .min(0, "Цена лекции должна быть неотрицательным числом"),
        })}
        onSubmit={(values, actions) =>
          SubmitCourse(values, (error) => {
            if (error.status == "404" || error.message === "Not Found")
              actions.setStatus("Файл не найден");
            else if (error.status == "401" || error.message === "Unauthorized")
              actions.setStatus(
                "Ошибка авторизации. Попробуйте выйти и войти снова"
              );
            else
              actions.setStatus(
                "Произошла ошибка при отправке данных на сервер"
              );
          }).finally(() => {
            actions.setStatus();
            actions.setSubmitting(false);
          })
        }
      >
        {({ errors, status, touched, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="fullname">Название лекции</label>
              <Field
                name="fullname"
                type="text"
                className={
                  "form-control" +
                  (errors.fullname && touched.fullname ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="fullname"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <div className="form-check">
                <Field
                  name="onsale"
                  type="checkbox"
                  className={
                    "form-check-input" +
                    (errors.onsale && touched.onsale ? " is-invalid" : "")
                  }
                />
                <label htmlFor="onsale" className="form-check-label">
                  Выставить лекцию на продажу
                </label>
              </div>
              <ErrorMessage
                name="onsale"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group row">
              <label htmlFor="price" className="col-auto col-form-label">
                Цена лекции в рублях
              </label>
              <Field
                name="price"
                type="number"
                className={
                  "form-control col-auto" +
                  (errors.price && touched.price ? " is-invalid" : "")
                }
                min="0"
                style={{ width: "10em" }}
              />
              <ErrorMessage
                name="price"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">
                Подробное описание лекции (выводится на странице лекции)
              </label>
              <Field
                name="description"
                className={
                  "form-control" +
                  (errors.description && touched.description
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue } }) => (
                  <JoditEditor
                    value={value}
                    onBlur={(e) => {
                      setFieldValue("description", e.target.innerHTML);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="description"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="shortdescription">
                Краткое описание лекции (выводится в списке лекций)
              </label>
              <Field
                name="shortdescription"
                className={
                  "form-control" +
                  (errors.shortdescription && touched.shortdescription
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue } }) => (
                  <JoditEditor
                    value={value}
                    onBlur={(e) => {
                      setFieldValue("shortdescription", e.target.innerHTML);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="shortdescription"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="presentationpreview">
                Изображение лекций (отображается в каталоге)
              </label>
              <Field
                name="imageUrl"
                type="text"
                className={
                  "form-control" +
                  (errors.imageUrl && touched.imageUrl ? " is-invalid" : "")
                }
              >
                {({ field: { value }, form: { setFieldValue }, meta }) => (
                  <SelectFile
                    accept="image/jpeg,image/png"
                    prevFileUrl={value}
                    onChange={(file) => {
                      setFieldValue("imageUrl", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="imageUrl"
                component="div"
                className="invalid-feedback"
              />
              <div>
                <label>
                  Если файл не выбран, то будет использоваться{" "}
                  <span
                    id="viewImageInMoodle"
                    style={{
                      color: "var(--info)",
                      cursor: "help",
                      borderBottom: "1px dashed",
                    }}
                  >
                    изображение <FontAwesomeIcon icon={faImage} />
                  </span>
                  , назначенное автором в настройках в Moodle, или картинка по
                  умолчанию
                </label>
                <Tooltip
                  placement="right-start"
                  isOpen={tooltipOpen}
                  target="viewImageInMoodle"
                  toggle={() => setTooltipOpen((tooltipOpen) => !tooltipOpen)}
                >
                  <div className="files-preview img-thumbnail">
                    <ImagePlugged
                      src={course.moodleImageUrl}
                      plugSrc={MediaRoutes.DefultCoursePoster}
                      alt=""
                    />
                  </div>
                </Tooltip>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="authors">Выбор авторов леции</label>
              <Field
                name="authors"
                className={
                  "form-control" +
                  (errors.authors && touched.authors ? " is-invalid" : "")
                }
              >
                {({ field: { value }, form: { setFieldValue } }) => (
                  <SelectAuthors
                    name="authors"
                    authors={value}
                    users={users}
                    onChangeAuthors={(authors) =>
                      setFieldValue("authors", authors)
                    }
                  />
                )}
              </Field>
            </div>

            <div className="form-group">
              <label htmlFor="presentation">
                Презентация лекции в формате pdf/pptx
              </label>
              <Field
                name="presentation"
                type="text"
                className={
                  "form-control" +
                  (errors.presentationurl && touched.presentationurl
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue } }) => (
                  <SelectFile
                    accept="application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    prevFileUrl={value}
                    onChange={(file) => {
                      setFieldValue("presentation", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="presentation"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="presentationpreview">
                Изображение презентации (отображается на кнопке загрузки)
              </label>
              <Field
                name="presentationpreview"
                type="text"
                className={
                  "form-control" +
                  (errors.presentationurl && touched.presentationurl
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue }, meta }) => (
                  <SelectFile
                    accept="image/jpeg,image/png"
                    prevFileUrl={value}
                    onChange={(file) => {
                      setFieldValue("presentationpreview", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="presentationpreview"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="video">Ссылка на видео</label>
              <Field
                name="video"
                type="text"
                className={
                  "form-control" +
                  (errors.video && touched.video ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="video"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="videopreview">
                Заставка видео (отображается перед запуском видео)
              </label>
              <Field
                name="videopreview"
                type="text"
                className={
                  "form-control" +
                  (errors.presentationurl && touched.presentation
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue }, meta }) => (
                  <SelectFile
                    accept="image/jpeg,image/png"
                    prevFileUrl={value}
                    onChange={(file) => {
                      setFieldValue("videopreview", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="videopreview"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="section">Раздел</label>
              <Field
                name="section"
                type="text"
                className={
                  "form-control" +
                  (errors.section && touched.section ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="section"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="speciality">Специальность</label>
              <Field
                name="speciality"
                type="text"
                className={
                  "form-control" +
                  (errors.speciality && touched.speciality ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="speciality"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="department">Кафедра</label>
              <Field
                name="department"
                type="text"
                className={
                  "form-control" +
                  (errors.department && touched.department ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="department"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Сохранить
              </button>
              <button
                type="reset"
                className="btn btn-light btn-outer-primary"
                disabled={isSubmitting}
                onClick={() =>
                  props.history.push(
                    Routes.getActual("Course", course.id).route
                  )
                }
              >
                Отменить
              </button>
              {isSubmitting ? (
                <Spinner />
              ) : (
                <ErrorMessage
                  name="general"
                  component="div"
                  className="invalid-feedback"
                />
              )}
            </div>
            {status && <div className={"alert alert-danger"}>{status}</div>}
          </Form>
        )}
      </Formik>
    </>
  );
};
