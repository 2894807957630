import React from 'react';
import { Redirect } from 'react-router-dom';
import Routes from '../Routes';
import { useAuthContext } from '../_services/authentication.service';

export const Logout = () => {
  const { user, logout } = useAuthContext();

  if (user) {
    logout();
  }

  return (
    <Redirect to={Routes.Home} />
  );
}
