const server =
  (window.app && window.app.env.SERVER_URL) ||
  process.env.REACT_APP_SERVER_URL ||
  window.location;
const apiPath =
  (window.app && window.app.env.API_PATH) ||
  process.env.REACT_APP_API_PATH ||
  '/api';
const moodleHost = getMoodleHost();

function getMoodleHost() {
  return (window.app && window.app.env.MOODLE_PATH) ||
  process.env.REACT_APP_API_PATH ||
  '/moodle';
}

export { server, apiPath, moodleHost };

const apiUrl = new URL(apiPath, server);

export function getBackendApiUrl(path) {
  let url = apiUrl + (path[0] === '/' ? '' : '/') + path;
  return url;
}

export function getMoodleUrl(path) {
  // path может быть как относительным, так и абсолютным, но с локальным хостом
  // в любом случае должен вести в Moodle
  const resourceUrl = new URL(path, moodleHost || getMoodleHost());
  const moodleUrl = new URL(moodleHost || getMoodleHost());
  resourceUrl.protocol = moodleUrl.protocol;
  resourceUrl.host = moodleUrl.host;
  //let url = moodleUrl + (path[0] === '/' ? '' : '/') + path;
  return resourceUrl.href;
}

export function getMoodleCourseUrl(id) {
  return getMoodleUrl(`/course/view.php?id=${id}`);
}