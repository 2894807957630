import React, { useEffect, useState } from "react";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import ListGroup from "reactstrap/lib/ListGroup";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import Row from "reactstrap/lib/Row";
import { getMoodleUrl } from "../_helpers/backend.url";
import { Loading } from "./Loading";
import { SearchBar } from "./SearchBar";

const SelectAuthorsListItem = ({ user, onSelect }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    user.active = active;
    onSelect(active);
  }, [active, user, onSelect]);
  return (
    <ListGroupItem
      active={active}
      style={{ cursor: "pointer", padding: "0.25rem" }}
      onClick={() => {
        setActive((a) => !a);
      }}
    >
      <div>
        <img
          src={getMoodleUrl(user.profileImageUrl)}
          alt=""
          style={{ height: "2rem" }}
        />
        <span className="mx-2 my-auto">{user.fullName}</span>
      </div>
    </ListGroupItem>
  );
};

const SelectAuthorsList = ({ users, onCountChange }) => {
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(undefined);

  useEffect(() => {
    onCountChange(count);
  }, [count, onCountChange]);

  const filteredUsers =
    users && search
      ? users.filter((u) => u.fullName.toLowerCase().includes(search))
      : users;

  return (
    <>
      <SearchBar
        placeholder="Поиск по ФИО"
        onSearch={(value) => setSearch(value.toLowerCase())}
      />
      <div
        style={{ height: "280px", overflowY: "auto", padding: "0" }}
        className="form-control"
      >
        <ListGroup flush>
          {filteredUsers &&
            filteredUsers.map((user) => (
              <SelectAuthorsListItem
                key={user.id}
                user={user}
                onSelect={() => setCount(users.filter((u) => u.active).length)}
              />
            ))}
        </ListGroup>
      </div>
    </>
  );
};

export const SelectAuthors = ({ authors, users, onChangeAuthors }) => {
  const [canAdd, setCanAdd] = useState(false);
  const [canRemove, setCanRemove] = useState(false);

  const addAuthors = () => {
    const newAuthors = users.filter((u) => u.active);
    onChangeAuthors([...authors, ...newAuthors]);
    newAuthors.forEach((author) => {
      author.active = undefined;
    });
  };
  const removeAuthors = () => {
    onChangeAuthors(authors.filter((a) => !a.active));
  };

  if (!authors || !users) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <label>Авторы (преподаватели) лекции</label>
        <SelectAuthorsList
          users={authors}
          onCountChange={(cnt) => setCanRemove(cnt > 0)}
        />
      </Col>

      <Col className="col-2 d-flex flex-column justify-content-center">
        <Button className="my-3" onClick={addAuthors} disabled={!canAdd}>
          Добавить &lt;&lt;
        </Button>
        <Button className="my-3" onClick={removeAuthors} disabled={!canRemove}>
          Удалить &gt;&gt;
        </Button>
      </Col>

      <Col>
        <label>Потенциальные авторы</label>
        <SelectAuthorsList
          users={users.filter((user) => !authors.some((a) => a.id === user.id))}
          onCountChange={(cnt) => setCanAdd(cnt > 0)}
        />
      </Col>
    </Row>
  );
};
