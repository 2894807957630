import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';
import { ItemsPerPage } from './ItemsPerPage';

export const Pagination = ({ itemsCount, defaultItemsPerPage, defaultSelectedPage, onPageChanged, children }) => {
  const [selectedPage, setSelectedPage] = useState(defaultSelectedPage || 0);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const pageCount = Math.ceil(itemsCount / itemsPerPage);
  function handlePageClick({ selected: selectedPage }) {
    setSelectedPage(selectedPage);
    onPageChanged(selectedPage, itemsPerPage);
  }

  function handleItemsPerPageClick(newItemsPerPage) {
    setItemsPerPage(newItemsPerPage);
    const newSelectedPage = Math.floor(selectedPage * itemsPerPage / newItemsPerPage);
    setSelectedPage(newSelectedPage);
    onPageChanged(newSelectedPage, newItemsPerPage);
  }

  return (
    <>
      {children}
      {pageCount > 1 &&
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          forcePage={selectedPage}
          pageCount={pageCount}
          pageRangeDisplayed={7}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          activeClassName="active"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          activeLinkClassName="active"
          disabledClassName="disabled"
        />
      }
      {itemsCount > 10 &&
        <ItemsPerPage
          defaultItemsPerPage={defaultItemsPerPage}
          options={[5, 10, 20, 50]}
          onChangeItemsPerPage={handleItemsPerPageClick}
        />
      }
    </>
  );
}