import React, { useState } from "react";
import { NavLink } from "reactstrap";

const ItemsPerPageButton = ({ count, selectedCount, onClick }) => (
  <NavLink
    href="#"
    className={
      "mx-1 px-0" +
      (count == selectedCount ? " text-primary" : " text-secondary")
    }
    onClick={() => onClick(count)}
  >
    {count > 0 ? count : "Все"}
  </NavLink>
);

export const ItemsPerPage = ({
  defaultItemsPerPage,
  options,
  onChangeItemsPerPage,
}) => {
  const [selected, setSelected] = useState(defaultItemsPerPage || 2);
  const defaultOptions = [5, 10, 20, 50, -1];

  function SetItemsPerPage(itemsPerPage) {
    setSelected(itemsPerPage);
    onChangeItemsPerPage(itemsPerPage);
  }

  return (
    <div className="btn-toolbar justify-content-end" role="toolbar">
      <div className="align-self-center">Лекций и курсов на странице:</div>
      {(options || defaultOptions)?.map((option) => (
        <ItemsPerPageButton
          key={option}
          count={option}
          selectedCount={selected}
          onClick={SetItemsPerPage}
        />
      ))}
    </div>
  );
};
