import React from 'react';
import { DownloadPresentation } from '../_components/DownloadPresentation';
import { VideoPlayer } from '../_components/VideoPlayer/VideoPlayer';
import { MediacontentItem } from '../_components/MediacontentItem';

export const Mediacontent = ({ presentationUrl, presentationPreviewUrl, videoUrl, videoPreviewUrl}) => (
  <div>
    <h4>Медиаконтент</h4>
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {presentationUrl &&
        <MediacontentItem title="Презентация">
          <DownloadPresentation previewImageUrl={presentationPreviewUrl} presentationUrl={presentationUrl} />
        </MediacontentItem>
      }
      {videoUrl &&
        <MediacontentItem title="Видеоролик">
          <VideoPlayer previewURl={videoPreviewUrl} videoUrl={videoUrl} />
        </MediacontentItem>
      }
      {(!presentationUrl && !videoUrl) &&
        <p>Медиаконтент отсутствует</p>
      }
    </div>
  </div>
);