import React, { useState, useEffect } from "react";
import $ from "jquery";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import { Loading } from "../_components/Loading";
import Routes from "../Routes";
import { useAuthContext } from "../_services/authentication.service";

export const CreateCourse = (props) => {
  const { requestBackend } = useAuthContext();
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function populateCourseData() {
      requestBackend({
        url: "/courses/categories/all",
        onSuccess: (data) => {
          setCategories(data);
          setLoading(false);
        },
      });
    }
    setLoading(true);
    populateCourseData();
  }, [requestBackend]);

  function SubmitCourse(formData, onError) {
    //console.log(formData);
    const courseData = {
      name: formData.fullname,
      categoryId: formData.categoryId,
    };

    requestBackend({
      url: "/courses",
      type: "POST",
      data: courseData,
      onSuccess: (courseId) => {
        props.history.push(Routes.getActual("EditCourse", courseId).route);
      },
      onFail: (_, textStatus, errorThrown) => {
        onError({ status: textStatus, message: errorThrown });
      },
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BreadcrumbsItem to={Routes.CreateCourse.route}>
        {Routes.CreateCourse.title}
      </BreadcrumbsItem>
      <h2>Создание лекции</h2>
      <div>
        При успешном создании лекции вы будете перенаправлены на страницу для
        редактирования других его параметров.
      </div>
      <Formik
        initialValues={{
          fullname: "",
          categoryId: categories[0].id || 0,
        }}
        validationSchema={Yup.object().shape({
          fullname: Yup.string()
            .required("Название лекции не может быть пустым")
            .test(
              "uniqueName",
              "Лекция с таким названием уже существует",
              (value) => {
                return new Promise((resolve, _) => {
                  requestBackend({ url: "/courses/exists?name=" + value })
                    .then(() => {
                      // exists
                      resolve(false);
                    })
                    .catch(() => {
                      // note exists
                      resolve(true);
                    });
                });
              }
            ),
          onsale: Yup.number().oneOf(
            categories?.map((c) => c.id) || [],
            "Выберите категорию из списка"
          ),
        })}
        onSubmit={(values, actions) =>
          SubmitCourse(values, (error) => {
            actions.setSubmitting(false);
            if (error.status == "401" || error.message === "Unauthorized")
              actions.setStatus(
                "Ошибка авторизации. Попробуйте выйти и войти снова"
              );
            else
              actions.setStatus(
                "Не удалось создать лекцию с указанным названием и категорией"
              );
          }).finally(() => {
            actions.setStatus();
            actions.setSubmitting(false);
          })
        }
      >
        {({ errors, status, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="fullname">Название лекции</label>
              <Field
                name="fullname"
                type="text"
                className={
                  "form-control" +
                  (errors.fullname && touched.fullname ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="fullname"
                component="div"
                className="invalid-feedback"
              />
              <label>
                <span className="text-danger">*</span> Это обязательное поле
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="categoryId">Категория</label>
              <Field
                name="categoryId"
                as="select"
                className={
                  "form-control" +
                  (errors.categoryId && touched.categoryId ? " is-invalid" : "")
                }
              >
                {categories &&
                  categories.map((category, i) => (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                ;
              </Field>
              <ErrorMessage
                name="categoryId"
                component="div"
                className="invalid-feedback"
              />
              <label>
                <span className="text-danger">*</span> Категория необходима для
                группировки лекций внутри Moodle. Это обязательное поле
              </label>
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Создать лекцию &gt;
              </button>
              <button
                type="reset"
                className="btn btn-light btn-outer-primary"
                disabled={isSubmitting}
                onClick={() => props.history.push(Routes.Admin.route)}
              >
                Отменить
              </button>
              {isSubmitting ? (
                <Spinner />
              ) : (
                <ErrorMessage
                  name="general"
                  component="div"
                  className="invalid-feedback"
                />
              )}
            </div>
            {status && <div className={"alert alert-danger"}>{status}</div>}
          </Form>
        )}
      </Formik>
    </>
  );
};
