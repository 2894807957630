import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ImagePlugged } from './ImagePlugged';
import { MediaRoutes } from '../Routes';

import './DownloadPresentation.css';

export const DownloadPresentation = ({ previewImageUrl, presentationUrl }) => (
  <div className="download-wrapper">
    <ImagePlugged src={previewImageUrl} plugSrc={MediaRoutes.DefultPresentationPreview} className="img-fluid img-thumbnail download-previewimg" />
    <div className="download-btn">
      {presentationUrl ?
        <DownloadFile url={presentationUrl}><FontAwesomeIcon icon={faDownload} /></DownloadFile>
        :
        <FontAwesomeIcon icon={faBan} size="3x" />
      }
    </div>
  </div>
);

export const DownloadFile = ({url, children, ...props}) => (
  <a href={url} target="_blank" {...props}>{children}</a>
);