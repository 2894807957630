import React, { useState } from "react";
import { InputGroup, Row, Col, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "./SearchBar.css";

export const SearchBar = ({ onSearch, placeholder, defaultValue }) => {
  const [term, setTerm] = useState(defaultValue || "");

  const dataSearch = () => {
    onSearch(term);
  };

  const clearSearch = () => {
    setTerm("");
    onSearch("");
  };

  return (
    <>
      <div>
        <InputGroup className="input-group-sm search-panel">
          <input
            className="form-control search-input"
            value={term}
            type="text"
            placeholder={placeholder}
            onChange={(e) => setTerm(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dataSearch();
                e.stopPropagation();
                e.preventDefault();
                return false;
              }
            }}
          />
          <div className="input-group-append" onClick={dataSearch}>
            <button className="btn search-button" type="button">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </InputGroup>
      </div>
      <div className="d-flex justify-content-end">
        <NavLink href="#" className="search-clear" onClick={clearSearch}>
          Очистить параметры поиска
        </NavLink>
      </div>
    </>
  );
};
