import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useAuthContext } from "../_services/authentication.service";
import { CatalogList } from "../Catalog/CatalogList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Routes from "../Routes";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "reactstrap/lib/Button";

export const AdminCourses = () => {
  const { user, requestBackend } = useAuthContext();
  //const user = getUser();
  const [courses, setCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);

  // load all courses and programs
  useEffect(() => {
    $.when(
      requestBackend({
        url: "/courses/all",
        onSuccess: (data) => {
          setCourses(data.filter((course) => course.id !== 1));
        },
      }),
      requestBackend({
        url: "/programs/all",
        onSuccess: (data) => {
          setPrograms(data);
        },
      })
    ).then(function () {
      setLoading(false);
    });
  }, [user, requestBackend]);

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <Link to={Routes.CreateCourse.route}>
          <Button color="primary">
            {Routes.CreateCourse.title} <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Link>
      </div>
      <CatalogList
        title="Все лекции"
        loading={loading}
        courses={courses}
        programs={programs}
        emptyMessage="Лекции не найдены"
      />
    </>
  );
};
