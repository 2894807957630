import React, { useState, useEffect } from "react";
import $ from "jquery";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";
import { Spinner } from "reactstrap";
import { Loading } from "../_components/Loading";
import { SelectFile, updateFile } from "../_components/UploadFile";
import Routes from "../Routes";
import { useAuthContext } from "../_services/authentication.service";

export const EditProgram = (props) => {
  const id = props.match.params.id;
  const [program, setProgram] = useState(null);
  const [loading, setLoading] = useState(true);
  const { requestBackend } = useAuthContext();

  useEffect(() => {
    function populateProgramData(programId) {
      requestBackend({
        url: `/programs/${programId}`,
        onSuccess: (data) => {
          setProgram(data);
          setLoading(false);
        },
      });
    }
    setLoading(true);
    populateProgramData(id);
  }, [id, requestBackend]);

  function SubmitProgram(formData, onError) {
    const putData = {
      fullName: formData.fullname,
      onSale: formData.onsale,
      price: formData.price,
      description: formData.description,
      shortDescription: formData.shortdescription,
      videoUrl: formData.video,
      section: formData.section,
      speciality: formData.speciality,
      department: formData.department,
    };

    const uploads = [];

    uploads.push(
      updateFile(
        program.presentationUrl,
        formData.presentation,
        (newUrl) => (putData["presentationUrl"] = newUrl),
        requestBackend
      )
    );
    uploads.push(
      updateFile(
        program.presentationPreviewUrl,
        formData.presentationpreview,
        (newUrl) => (putData["presentationPreviewUrl"] = newUrl),
        requestBackend
      )
    );
    uploads.push(
      updateFile(
        program.videoPreviewUrl,
        formData.videopreview,
        (newUrl) => (putData["videoPreviewUrl"] = newUrl),
        requestBackend
      )
    );

    return Promise.all(uploads)
      .then(() => {
        requestBackend({
          url: `/programs/${id}`,
          type: "PUT",
          data: putData,
          onSuccess: () => {
            props.history.push(Routes.getActual("Program", id).route);
          },
          onFail: (_, textStatus, errorThrown) => {
            onError({ status: textStatus, message: errorThrown });
          },
        });
      })
      .catch((err) => onError(err));
  }

  if (loading) return <Loading />;

  const thisRoute = Routes.getActual(
    "EditProgram",
    program.id,
    program.fullName
  );

  return (
    <>
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>
      <Formik
        initialValues={{
          fullname: program.fullName,
          price: program.price,
          description: program.description,
          presentation: program.presentationUrl,
          presentationpreview: program.presentationPreviewUrl,
          video: program.videoUrl,
          videopreview: program.videoPreviewUrl,
          section: program.section,
          speciality: program.speciality,
          department: program.department,
          general: "",
        }}
        validationSchema={Yup.object().shape({
          fullname: Yup.string().required(
            "Название курса не может быть пустым"
          ),
          price: Yup.number()
            .required("Укажите цену курса")
            .min(0, "Цена курса должна быть неотрицательным числом"),
        })}
        onSubmit={(values, actions) =>
          SubmitProgram(values, (error) => {
            if (error.status == "404" || error.message === "Not Found")
              actions.setStatus("Файл не найден");
            else if (error.status == "401" || error.message === "Unauthorized")
              actions.setStatus(
                "Ошибка авторизации. Попробуйте выйти и войти снова"
              );
            else
              actions.setStatus(
                "Произошла ошибка при отправке данных на сервер"
              );
          }).finally(() => {
            actions.setStatus();
            actions.setSubmitting(false);
          })
        }
      >
        {({ errors, status, touched, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="fullname">Название курса</label>
              <Field
                name="fullname"
                type="text"
                className={
                  "form-control" +
                  (errors.fullname && touched.fullname ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="fullname"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group row">
              <label htmlFor="price" className="col-auto col-form-label">
                Цена курса в рублях
              </label>
              <Field
                name="price"
                type="number"
                className={
                  "form-control col-auto" +
                  (errors.price && touched.price ? " is-invalid" : "")
                }
                min="0"
                style={{ width: "10em" }}
              />
              <ErrorMessage
                name="price"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Описание курса</label>
              <Field
                name="description"
                className={
                  "form-control" +
                  (errors.description && touched.description
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue } }) => (
                  <JoditEditor
                    value={value}
                    onBlur={(e) => {
                      setFieldValue("description", e.target.innerHTML);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="description"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="presentation">
                Презентация курса в формате pdf/pptx
              </label>
              <Field
                name="presentation"
                type="text"
                className={
                  "form-control" +
                  (errors.presentationurl && touched.presentationurl
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue } }) => (
                  <SelectFile
                    accept="application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                    prevFileUrl={value}
                    onChange={(file) => {
                      setFieldValue("presentation", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="presentation"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="presentationpreview">
                Изображение презентации (отображается на кнопке загрузки)
              </label>
              <Field
                name="presentationpreview"
                type="text"
                className={
                  "form-control" +
                  (errors.presentationurl && touched.presentationurl
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue }, meta }) => (
                  <SelectFile
                    accept="image/jpeg,image/png"
                    prevFileUrl={value}
                    onChange={(file) => {
                      setFieldValue("presentationpreview", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="presentationpreview"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="video">Ссылка на видео</label>
              <Field
                name="video"
                type="text"
                className={
                  "form-control" +
                  (errors.video && touched.video ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="video"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="videopreview">
                Заставка видео (отображается перед запуском видео)
              </label>
              <Field
                name="videopreview"
                type="text"
                className={
                  "form-control" +
                  (errors.presentationurl && touched.presentation
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue }, meta }) => (
                  <SelectFile
                    accept="image/jpeg,image/png"
                    prevFileUrl={value}
                    onChange={(file) => {
                      setFieldValue("videopreview", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="videopreview"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="section">Раздел</label>
              <Field
                name="section"
                type="text"
                className={
                  "form-control" +
                  (errors.section && touched.section ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="section"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="speciality">Специальность</label>
              <Field
                name="speciality"
                type="text"
                className={
                  "form-control" +
                  (errors.speciality && touched.speciality ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="speciality"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label htmlFor="department">Кафедра</label>
              <Field
                name="department"
                type="text"
                className={
                  "form-control" +
                  (errors.department && touched.department ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="department"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Сохранить
              </button>
              <button
                type="reset"
                className="btn btn-light btn-outer-primary"
                disabled={isSubmitting}
                onClick={() =>
                  props.history.push(
                    Routes.getActual("Program", program.id).route
                  )
                }
              >
                Отменить
              </button>
              {isSubmitting ? (
                <Spinner />
              ) : (
                <ErrorMessage
                  name="general"
                  component="div"
                  className="invalid-feedback"
                />
              )}
            </div>
            {status && <div className={"alert alert-danger"}>{status}</div>}
          </Form>
        )}
      </Formik>
    </>
  );
};
