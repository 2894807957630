import React from "react";
import { getRusNumbersEnding } from "../_helpers/FormatStrings";

export const EnrollerdUsersCount = ({ count, itemType = "Лекцию" }) => {
  const people = "человек" + getRusNumbersEnding(count, "", "a", "");
  const buyed = "приобрел" + getRusNumbersEnding(count, "", "и", "и");
  return (
    <div className="text-secondary my-3">
      {count > 0 ? (
        <>
          {itemType} {buyed} <strong className="text-important">{count}</strong>{" "}
          {people}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export const EnrollerdUsersCountCard = ({ count, itemType = "Лекцию" }) => {
  const people = "человек" + getRusNumbersEnding(count, "", "a", "");
  const buyed = "приобрел" + getRusNumbersEnding(count, "", "и", "и");
  return (
    <div
      className="text-secondary text-center m-2"
      style={{ fontSize: "12px" }}
    >
      {count > 0 ? (
        <>
          {itemType} {buyed}
          <br />
          <strong className="text-important">{count}</strong> {people}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
