import React, { useState, useEffect } from "react";
import $ from "jquery";
import { CatalogList } from "./CatalogList";
import { useAuthContext } from "../_services/authentication.service";

export const Catalog = () => {
  const { user, requestBackend } = useAuthContext();
  //const user = getUser();
  const [courses, setCourses] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [loading, setLoading] = useState(true);

  // load courses and programs
  useEffect(() => {
    $.when(
      requestBackend({
        url: "/courses" + (user ? `?currentUserId=${user.id}` : ""),
        onSuccess: (data) => {
          setCourses(data);
        },
      }),
      requestBackend({
        url: "/programs" + (user ? `?currentUserId=${user.id}` : ""),
        onSuccess: (data) => {
          setPrograms(data);
        },
      })
    ).then(function () {
      setLoading(false);
    });
  }, [user]);

  return (
    <CatalogList
      title="Подходящие лекции"
      loading={loading}
      courses={courses || []}
      programs={programs || []}
    />
  );
};
