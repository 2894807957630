import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../Routes';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

export const NotFoundPage = () => {
  return (
    <>
      <BreadcrumbsItem to={window.location.pathname}></BreadcrumbsItem>
      <h1>Страница не найдена</h1>
      <p>Извините, запрошенный вами ресурс не найден. Возможно, он был удален или перемещен, а вы пришли по устаревшей ссылке или неверно ввели адрес.</p>
      <p><Link to={Routes.Home.route}>Перейти на главную страницу</Link></p>
    </>
  );
}