import React from 'react';
import { CourseCard, ProgramCard } from './CatalogCard';

export const CatalogItem = ({ item }) => (
  <div className="mb-3">
    {item["courseIds"] ?
      <ProgramCard program={item} />
      :
      <CourseCard course={item} />
      }
  </div>
);