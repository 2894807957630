import React from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { EnrollerdUsersCountCard } from "./EnrolledUsersCount";
import { CourseActionLink, ProgramActionLink } from "./CatalogActions";
import { MarkupedText } from "../_components/MarkupedText";
import { AuthorsInfoShort } from "./AuthorInfo";
import Routes, { MediaRoutes } from "../Routes";
import { ImagePlugged } from "../_components/ImagePlugged";
import { getMoodleUrl } from "../_helpers/backend.url";

import "./CatalogCard.css";

//    const startDate = new Date(course.startDate * 1000);
//<Col>
//                  {!course.enrolledByCurrentUser
//                    ?
//                    : (startDate > Date.now()
//                      ? <div className="text-secondary">Доступно с: {startDate.toLocaleString('ru', { year: 'numeric', month: 'numeric', day: 'numeric' })}
//                      </div>
//                      : <div className="text-secondary">Лекция уже доступна</div>
//                    )
//                  }
//                </Col>

export const ProgramCard = ({ program }) => {
  return (
    <Card className="programcard">
      <div className="programcard-header">
        <h2>{program.fullName}</h2>
      </div>
      <div>
        {program.courses &&
          program.courses.map((course) => (
            <CourseCard key={course.id} course={course} injected={true} />
          ))}
      </div>
      <div className="programcard-footer">
        <ProgramActionLink
          programId={program.id}
          price={program.price}
          enrolled={program.enrolledByCurrentUser}
        />
      </div>
    </Card>
  );
};

export const CourseCard = ({ course, injected = false }) => {
  const courseRoute = Routes.getActual("Course", course.id, course.fullName);
  return (
    <Card className={"catalogcard" + (injected ? " injected" : "")}>
      <div className="catalogcard-aside">
        <ImagePlugged
          src={course.imageUrl || getMoodleUrl(course.moodleImageUrl)}
          plugSrc={MediaRoutes.DefultCoursePoster}
          className="catalogcard-img"
          alt=""
        />
        <div style={{ width: "120px" }}>
          <EnrollerdUsersCountCard count={course.enrolledUsersCount} />
        </div>
      </div>
      <div className="catalogcard-main">
        <Link to={courseRoute.route}>
          <h3>{course.fullName}</h3>
        </Link>
        <AuthorsInfoShort authors={course.authors} />
        {course.shortDescription && (
          <div className="my-2">
            <strong>Аннотация</strong>
            <MarkupedText description={course.shortDescription} />
          </div>
        )}
      </div>
      <div className="catalogcard-buy">
        <div className="catalogcard-price">
          <div>Стоимость лекции {injected ? " отдельно" : ""}</div>
          <div className="catalogcard-price-value">
            {course.price.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
              minimumFractionDigits: 0,
            })}
          </div>
        </div>
        <CourseActionLink
          courseId={course.id}
          enrolled={course.enrolledByCurrentUser}
        />
      </div>
    </Card>
  );
};
