import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthContext } from "../_services/authentication.service";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faUserCircle,
  faQuestionCircle,
  faPlus,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { userName } from "../_helpers/FormatStrings";
import { Role } from "../_helpers/role";
import "./HeaderAccount.css";
import iconEnter from "../_img/icon_metro-enter.svg";
import Routes from "../Routes";
import { DownloadFile } from "../_components/DownloadPresentation";
import { useKeycloak } from "@react-keycloak/web";

export const HeaderAccount = () => {
  const [keycloak] = useKeycloak();
  const { user, createAccountUrlSso, requestBackend, loginSso } =
    useAuthContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isRedirecting, setisRedirecting] = useState(false);

  // load courses and programs  of the user
  useEffect(() => {
    if (user) {
      requestBackend({
        url: `/payment/check/${user.id}`,
        type: "POST",
      });
    }
  }, [user, requestBackend]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="d-sm-inline-flex flex-sm-row">
      {isRedirecting || (keycloak.authenticated && !user) ? (
        <Spinner size="sm" className="link" />
      ) : keycloak.authenticated && user ? (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle tag={Link} to="#">
            {userName(user.fullName)}{" "}
            <FontAwesomeIcon icon={faUserCircle} size="lg" />
          </DropdownToggle>

          <DropdownMenu right>
            {user.role === Role.Admin || user.role === Role.Manager ? (
              <>
                <DropdownItem tag={Link} to={Routes.Admin.route}>
                  {Routes.Admin.title} <FontAwesomeIcon icon={faCog} />
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.CreateCourse.route}>
                  {Routes.CreateCourse.title} <FontAwesomeIcon icon={faPlus} />
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.Authors.route}>
                  {Routes.Authors.title} <FontAwesomeIcon icon={faUserEdit} />
                </DropdownItem>
                <DropdownItem tag={DownloadFile} url={Routes.AdminHelp.route}>
                  {Routes.AdminHelp.title}{" "}
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.EditPublicOffer.route}>
                  {Routes.EditPublicOffer.title}
                </DropdownItem>
              </>
            ) : (
              <DropdownItem tag={Link} to={Routes.AccountCourses.route}>
                {Routes.AccountCourses.title}
              </DropdownItem>
            )}
            <DropdownItem tag="a" href={createAccountUrlSso()}>
              {Routes.Account.title}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag={Link} to={Routes.Logout.route}>
              Выйти
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <button
          onClick={() => {
            setisRedirecting(true);
            loginSso();
          }}
          className="btn btn-link link mx-1"
        >
          Войти <img src={iconEnter} />
        </button>
      )}
    </div>
  );
};
