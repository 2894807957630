import React, { useEffect, useState } from "react";
import { rubles } from "../_helpers/FormatStrings";
import { Spinner } from "reactstrap";
import { useAuthContext } from "../_services/authentication.service";
import { MoodleCourseLink } from "./CatalogActions";
import { Link, Redirect } from "react-router-dom";
import { Loading } from "../_components/Loading";
import Routes from "../Routes";
import { PublicOffer } from "./PublicOffer";

export const CheckPayment = (props) => {
  let paymentRedirectUrl = localStorage.getItem("paymentRedirectUrl");

  if (!paymentRedirectUrl) {
    return (
      <Redirect
        to={{
          pathname: Routes.Catalog.route,
          state: { from: props.location },
        }}
      />
    );
  }

  paymentRedirectUrl = JSON.parse(paymentRedirectUrl);
  const searchParams = new URLSearchParams(window.location.search);
  const failed = searchParams.has("failed");

  return (
    <Redirect
      to={{
        pathname: failed
          ? paymentRedirectUrl.failUrl
          : paymentRedirectUrl.returnUrl,
        state: { from: props.location },
      }}
    />
  );
};

const ProcessBuy = ({ itemId, itemType, itemPrice, location }) => {
  const { user, requestBackend } = useAuthContext();
  const searchParams = new URLSearchParams(location.search);
  const failed = searchParams.has("failed");
  const [paymentProcessResult, setPaymentProcessResult] = useState(null);
  const [acceptedPublicOffer, setAcceptedPublicOffer] = useState(
    searchParams.has("accepted") ? searchParams.get("accepted") : undefined
  );

  useEffect(() => {
    if (itemId && user.id && requestBackend) {
      const returnUrl =
        window.location.pathname + "?accepted=" + !!acceptedPublicOffer;
      const failUrl = window.location.pathname + "?failed=true";
      localStorage.setItem(
        "paymentRedirectUrl",
        JSON.stringify({ returnUrl, failUrl })
      );
      requestBackend({
        url: `/payment/${itemType}?accepted=` + !!acceptedPublicOffer,
        method: "POST",
        data: {
          userId: user.id,
          itemId: itemId,
          returnUrl: window.location.origin + returnUrl,
          failUrl: window.location.origin + failUrl,
        },
        onSuccess: (result) => {
          setPaymentProcessResult(result);
        },
        onFail: () => {
          setPaymentProcessResult({ statusCode: -99 });
        },
      });
    }
  }, [itemId, user.id, requestBackend, itemPrice, acceptedPublicOffer]);

  if (acceptedPublicOffer === false) {
    return (
      <Redirect
        to={{
          pathname: Routes.Catalog.route,
          state: { from: location },
        }}
      />
    );
  }

  if (failed) {
    console.warn(failed);
    return (
      <>
        <p>Платеж был отклонен банком. Поробуйте позже.</p>
        <Link to={Routes.Catalog.route}>Вернуться в каталог</Link>
      </>
    );
  }

  // оплата еще не начата
  if (paymentProcessResult == null) {
    return (
      <>
        <p>Подождите, выполняется обработка...</p>
        <Loading />
      </>
    );
  }

  // enrolled
  if (paymentProcessResult.statusCode === 1) {
    return (
      <>
        <p>Вы успешно зачислены</p>
        <MoodleCourseLink id={itemId} />
      </>
    );
  }

  // accept public offer
  if (paymentProcessResult.statusCode === 3) {
    return (
      <PublicOffer
        publicOfferFileIrl={paymentProcessResult.targetUrl}
        onSubmit={() => {
          setAcceptedPublicOffer(true);
          setPaymentProcessResult(null);
          searchParams.set("accepted", "true");
        }}
        onCancel={() => setAcceptedPublicOffer(false)}
      />
    );
  }

  // redirect to bank
  if (paymentProcessResult.statusCode === 2 && paymentProcessResult.targetUrl) {
    window.location.assign(paymentProcessResult.targetUrl);
    return (
      <>
        <p>
          Пожалуйста, подождите. Сейчас вы будете перенаправлены на страницу
          платежной системы...
        </p>
        <p>
          Нажмите <a href={paymentProcessResult.bankPageUrl}>сюда</a>, если ваш
          браузер не поддерживает перенаправление
        </p>
        <Spinner />
      </>
    );
  }

  // произошла ошибка
  console.error(paymentProcessResult);
  return <p>Произошла ошибка при обработке запроса. Попробуйте зайти позже.</p>;
};

export const BuyCourse = ({ course, location }) => {
  const courseInfo = (
    <p>
      Вы перешли на страницу покупки лекции "{course.fullName}" стоимостью{" "}
      {rubles(course.price)}
    </p>
  );

  return (
    <>
      {courseInfo}
      <ProcessBuy
        itemId={course.id}
        itemType="course"
        itemPrice={course.price}
        location={location}
      />
    </>
  );
};

export const BuyProgram = ({ program, location }) => {
  const programInfo = (
    <p>
      Вы перешлина страницу покупки курса "{program.fullName}" стоимостью{" "}
      {rubles(program.price)}
    </p>
  );

  return (
    <>
      {programInfo}
      <ProcessBuy
        itemId={program.id}
        itemType="program"
        itemPrice={program.price}
        location={location}
      />
    </>
  );
};
