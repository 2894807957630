import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch, Link, Route } from "react-router-dom";
import Routes from "../Routes";
import { PrivateRoute } from "../_components/PrivateRoute";
import { Role } from "../_helpers/role";
import { AdminCourses } from "./AdminCourses";
import { Authors } from "./Authors";
import { CreateCourse } from "./CreateCourse";
import { EditAuthor } from "./EditAuthor";
import { EditCourse } from "./EditCourse";
import { EditProgram } from "./EditProgram";

export const AdminAuthorsPage = () => (
  <>
    <BreadcrumbsItem to={Routes.Authors.route}>
      {Routes.Authors.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.EditAuthor.route}
        component={EditAuthor}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.Authors.route}
        component={Authors}
      />
    </Switch>
  </>
);

export const AdminPage = () => (
  <>
    <BreadcrumbsItem to={Routes.Admin.route}>
      {Routes.Admin.title}
    </BreadcrumbsItem>
    <div className="m-4">
      <Switch>
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.CreateCourse.route}
          component={CreateCourse}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.EditCourse.route}
          component={EditCourse}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.EditProgram.route}
          component={EditProgram}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.Authors.route}
          component={AdminAuthorsPage}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.Admin.route}
          component={AdminCourses}
        />
      </Switch>
    </div>
  </>
);
