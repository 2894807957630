import React, { Component } from "react";
import { Container, Navbar, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { HeaderAccount } from "./HeaderAccount";
import Routes from "../Routes";

import "./NavMenu.css";
import logo from "../_img/navbar_logo.svg";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm navbar-main">
          <Container className="container-fluid">
            <div className="navbar-brand">
              <img src={logo} />
            </div>
            <div className="d-flex flex-column justify-content-end align-items-end">
              <div>
                <HeaderAccount />
              </div>
              <ul className="navbar-nav flex-grow d-sm-inline-flex">
                <NavItem>
                  <NavLink tag={Link} to={Routes.Catalog.route}>
                    Каталог курсов
                  </NavLink>
                </NavItem>
                <NavItem>
                  <a href="https://helpdesk.samsmu.ru/ru/" className="nav-link">
                    Помощь
                  </a>
                </NavItem>
                <NavItem>
                  <a href="https://samsmu.ru/contacts/" className="nav-link">
                    Контакты
                  </a>
                </NavItem>
              </ul>
            </div>
          </Container>
        </Navbar>
      </header>
    );
  }
}
