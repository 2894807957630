import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Row, Col } from "reactstrap";
import { EnrollerdUsersCount } from "./EnrolledUsersCount";
import { AuthorInfo } from "./AuthorInfo";
import { MarkupedText } from "../_components/MarkupedText";
import { CourseActionLink } from "./CatalogActions";
import Routes from "../Routes";
import { Mediacontent } from "../_components/Mediacontent";

export const CourseDetails = ({ course }) => {
  const thisRoute = Routes.getActual("Course", course.id, course.fullName);
  return (
    <>
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>

      <h1>Лекция "{course.fullName}"</h1>

      <Row>
        <Col className="col-sm-8 col-12">
          <EnrollerdUsersCount count={course.enrolledUsersCount} />
          <CourseActionLink
            courseId={course.id}
            price={course.price}
            enrolled={course.enrolledByCurrentUser}
          />
          <h4>Аннотация</h4>
          <MarkupedText description={course.description} />
        </Col>
        <Col className="col-sm-4 col-12">
          {course.authors &&
            course.authors.map((author) => (
              <AuthorInfo key={author.id} author={author} />
            ))}
        </Col>
      </Row>

      <Mediacontent
        presentationUrl={course.presentationUrl}
        presentationPreviewUrl={course.presentationPreviewUrl}
        videoUrl={course.videoUrl}
        videoPreviewUrl={course.videoPreviewUrl}
      />
    </>
  );
};
