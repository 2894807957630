import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Footer.css";

const FooterCopy = () => (
  <div>
    <div>© 1919—2020</div>
    <div>ФГБОУ ВО СамГМУ Минздрава России</div>
    <div>
      <a href="https://www.samsmu.ru/">Официальный сайт</a>
    </div>
  </div>
);

export const Footer = () => <div></div>;
