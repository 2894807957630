import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { nameParts } from "../_helpers/FormatStrings";
import { Spinner } from "reactstrap";
import Routes from "../Routes";
import JoditEditor from "jodit-react";
import { SelectFile } from "../_components/UploadFile";
import { useAuthContext } from "../_services/authentication.service";

export const AuthorForm = ({ author }) => {
  const { requestBackend } = useAuthContext();
  const history = useHistory();

  function handleSubmit(values, actions) {
    actions.setFieldValue("general", "");
    var formData = new FormData();
    formData.append("firstname", values.firstname);
    formData.append("middlename", values.midname);
    formData.append("lastname", values.lastname);
    formData.append("email", author.email);
    formData.append("description", values.description);
    if (values.profileImage && typeof values.profileImage !== "string") {
      formData.append(
        "profileImage",
        values.profileImage,
        values.profileImage.name
      );
    }
    formData.append("deleteProfileImage", values.profileImage === "");
    requestBackend({
      url: `/users/${author.id}`,
      type: "PUT",
      data: formData,
      processData: false,
      contentType: false,
    })
      .then(() => history.push(Routes.Authors.route))
      .catch(() => actions.setStatus("Произошла ошибка при сохранении данных"))
      .finally(() => actions.setSubmitting(false));
  }

  const [authorFirstname, authorMidname, authorLastname] = nameParts(
    author.fullName
  );

  return (
    <Formik
      initialValues={{
        firstname: authorFirstname,
        midname: authorMidname,
        lastname: authorLastname,
        description: author.description,
        profileImage: author.profileImageUrl,
      }}
      validationSchema={Yup.object().shape({
        firstname: Yup.string().required("Введите имя"),
        lastname: Yup.string().required("Введите фамилию"),
      })}
      onSubmit={handleSubmit}
    >
      {({ errors, status, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="lastname">Фамилия</label>
            <Field
              name="lastname"
              type="text"
              className={
                "form-control" +
                (errors.fullname && touched.fullname ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="lastname"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group">
            <label htmlFor="firstname">Имя</label>
            <Field
              name="firstname"
              type="text"
              className={
                "form-control" +
                (errors.fullname && touched.fullname ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="firstname"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <div className="form-group">
            <label htmlFor="midname">Отчество</label>
            <Field
              name="midname"
              type="text"
              className={
                "form-control" +
                (errors.fullname && touched.fullname ? " is-invalid" : "")
              }
            />
            <ErrorMessage
              name="midname"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">
              Описание автора (ученая степень, ученое звание и другая
              информация)
            </label>
            <Field
              name="description"
              className={
                "form-control" +
                (errors.description && touched.description ? " is-invalid" : "")
              }
            >
              {({ field: { value }, form: { setFieldValue } }) => (
                <JoditEditor
                  value={value}
                  onBlur={(e) => {
                    setFieldValue("description", e.target.innerHTML);
                  }}
                />
              )}
            </Field>
            <ErrorMessage
              name="description"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group">
            <label htmlFor="profileImage">Изображение профиля</label>
            <Field
              name="profileImage"
              type="text"
              className={
                "form-control" +
                (errors.profileImage && touched.profileImage
                  ? " is-invalid"
                  : "")
              }
            >
              {({ field: { value }, form: { setFieldValue } }) => (
                <SelectFile
                  accept="image/*,image/png,image/jpeg,image/gif"
                  prevFileUrl={value}
                  onChange={(file) => {
                    setFieldValue("profileImage", file);
                  }}
                />
              )}
            </Field>
            <ErrorMessage
              name="profileImage"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              Сохранить
            </button>
            <button
              type="reset"
              className="btn btn-light btn-outer-primary"
              disabled={isSubmitting}
              onClick={() => history.push(Routes.Authors.route)}
            >
              Отменить
            </button>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <ErrorMessage
                name="general"
                component="div"
                className="invalid-feedback"
              />
            )}
          </div>
          {status && <div className={"alert alert-danger"}>{status}</div>}
        </Form>
      )}
    </Formik>
  );
};
