import React from "react";
import PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";
import { ButtonGroup } from "reactstrap";

export const PublicOffer = ({ onSubmit, onCancel, publicOfferFileIrl }) => {
  const [agreed, setAgreed] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setSubmitted(true);
        onSubmit();
      }}
    >
      <div className="publicOffer">
        <p>Пожалуйста, ознакомьтесь с договором Публичной оферты.</p>
        <p>
          <a
            href={publicOfferFileIrl}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            Скачать договор Публичной оферты
          </a>
        </p>
        <div>
          <input
            id="publicofferAccept"
            type="checkbox"
            className={"form-check-input"}
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
            disabled={submitted}
          />
          <label htmlFor="publicofferAccept" className="form-check-label">
            Я прочитал(а) и согласен(на) с условиями Публичной оферты
          </label>
        </div>
        <p>
          После нажатия на кнопку &ldquo;Перейти к оплате&rdquo; вы будете
          перенаправлены на страницу банка для проведения платежной операции.
        </p>
      </div>
      <ButtonGroup>
        <Button
          type="submit"
          color="primary"
          className="popup-submit button"
          active={agreed && !submitted}
          disabled={!agreed || submitted}
        >
          Перейти к оплате
        </Button>

        <Button
          className="popup-cancel button"
          onClick={onCancel}
          disabled={submitted}
          outline
        >
          Отказаться
        </Button>
      </ButtonGroup>
    </form>
  );
};

PublicOffer.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  publicOfferFileIrl: PropTypes.string,
};
