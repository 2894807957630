import React from "react";
import { MediaRoutes } from "../../Routes";
import "./VideoPlayer.css";

export const VideoPlayer = ({ previewURl, videoUrl }) => {
  const fullPreviewUrl = new URL(
    previewURl || MediaRoutes.DefultVideoPreview,
    window.location.href
  );
  return videoUrl ? (
    <div className="video-container">
      <iframe
        title="promovideo"
        src={`${videoUrl}&poster=${fullPreviewUrl}`}
        width="380px"
        height="240px"
        scrolling="no"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen="allowfullscreen"
      />
    </div>
  ) : (
    ""
  );
};
