import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { NavItem, NavLink } from 'reactstrap';

import './SortingOption.css';

export const SortingOption = ({ text, active, descending, onSort }) => (
  <NavItem>
    <NavLink
      href="#"
      className={"sortoption" + (active ? " text-primary" : "")}
      onClick={onSort}
    >
      {text}
      {active
        ? descending
          ? <FontAwesomeIcon icon={faArrowUp} className="ml-1" />
          : <FontAwesomeIcon icon={faArrowDown} className="ml-1" />
        : ''
      }
    </NavLink>
  </NavItem>
);
