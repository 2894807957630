import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../_components/Loading";
import { BuyProgram } from "./BuyCourse";
import Routes from "../Routes";
import { ProgramDetails } from "./ProgramDetails";
import { useAuthContext } from "../_services/authentication.service";

import "./ProgramPage.css";

export const ProgramPage = (props) => {
  const id = props.match.params.id;
  const action = props.match.params.action;
  const { user, requestBackend } = useAuthContext();
  //const user = getUser();
  const [program, setProgram] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function populateProgramData(programId) {
      try {
        requestBackend({
          url: `/programs/${programId}${
            user ? `?currentUserId=${user.id}` : ""
          }`,
          onSuccess: (data) => {
            setProgram(data);
            setLoading(false);
          },
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
    setLoading(true);
    populateProgramData(id);
  }, [id, user, requestBackend]);

  return (
    <div style={{ marginTop: "1.5rem" }}>
      {loading ? (
        <Loading />
      ) : !program ? (
        <div>
          Курс не найден{" "}
          <Link to={Routes.Catalog.route}>Вернуться в каталог</Link>
        </div>
      ) : (
        <>
          {action === "buy" ? (
            <BuyProgram program={program} location={props.location} />
          ) : (
            <ProgramDetails program={program} />
          )}
        </>
      )}
    </div>
  );
};
