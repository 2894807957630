import React from 'react';
import { Container } from 'reactstrap';
import { Route, Switch, NavLink } from 'react-router-dom';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import './Header.css';
import Routes from '../Routes';

export const HeaderJumbotron = () => (
  <div className="navbar navbar-expand-lg header-jumbotron">
  </div>
);

const HeaderBreadCrumb = () => (
  <div className="navbar navbar-expand-lg header-breadcrumbs">
    <Container className="container-fluid">
      <Breadcrumbs
        separator={<FontAwesomeIcon icon={faCaretRight} className="mx-2" />}
        item={NavLink}
        finalItem={'span'}
      />
    </Container>
  </div>
);

export const Header = ({ courseName }) => (
  <Switch>
    <Route exact path={Routes.Home.route} component={HeaderJumbotron} />
    <Route component={() => <HeaderBreadCrumb courseName={courseName} />} />
  </Switch>
);