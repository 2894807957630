import React, { useState } from "react";
import { Input, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import "./UploadFile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faTimesCircle,
  faFilePdf,
  faFilePowerpoint,
  faTrash,
  faSyncAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

export function uploadFile(uploadedFile, onSuccess, onError, requestBackend) {
  if (!uploadedFile) return;
  const postData = new FormData();
  postData.append("uploadedFile", uploadedFile, uploadedFile.name);
  return requestBackend({
    url: "/files/",
    type: "POST",
    data: postData,
    processData: false,
    contentType: false,
    onSuccess: onSuccess,
    onFail: (_, textStatus, errorThrown) =>
      onError({ status: textStatus, message: errorThrown }),
  });
}

export function deleteFile(
  uploadedFileUrl,
  onSuccess,
  onError,
  requestBackend
) {
  if (!uploadedFileUrl) return;
  const deleteData = new FormData();
  deleteData.append("fileUrl", uploadedFileUrl);
  return requestBackend({
    url: "/files/",
    type: "DELETE",
    data: deleteData,
    processData: false,
    contentType: false,
    success: onSuccess,
    error: (_, textStatus, errorThrown) =>
      onError({ status: textStatus, message: errorThrown }),
  });
}

export const SelectFile = ({ accept, prevFileUrl, onChange }) => {
  const [origFileUrl] = useState(prevFileUrl);
  const [reset, setReset] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(prevFileUrl);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [errorHint, setErrorHint] = useState("");

  function onSelectFile(e) {
    const newFile = e.target.files[0];

    if (!accept.split(",").some((type) => newFile.type.match(type))) {
      //console.log(newFile.type);
      setErrorHint(
        "Неподдерживаемый тип файла. Выберите другой файл для загрузки"
      );
      setReset(!reset);
      return false;
    }

    setSelectedFile(newFile);
    //console.log(e.target.files[0]);
    onChange(newFile);
    setErrorHint("");
    setPreviewUrl(URL.createObjectURL(newFile));
  }

  function onClear(e) {
    setSelectedFile(undefined);
    onChange("");
    setErrorHint("");
    setPreviewUrl("");
  }

  function onReset() {
    setSelectedFile(undefined);
    onChange(undefined);
    setErrorHint("");
    setReset(!reset);
    setPreviewUrl(origFileUrl);
  }

  return (
    <Card>
      <CardHeader className="p-1">
        <button
          type="button"
          className={`btn mx-1 ${previewUrl ? "active" : "disabled"}`}
          onClick={onReset}
        >
          <FontAwesomeIcon icon={faSyncAlt} /> Вернуть
        </button>
        <button
          type="button"
          className={`btn mx-1 ${previewUrl ? "active" : "disabled"}`}
          onClick={onClear}
        >
          <FontAwesomeIcon icon={faTrash} /> Удалить
        </button>
      </CardHeader>
      <CardBody className="p-2">
        <Row>
          <Col className="col-12 col-md-3 text-center files-preview text-truncate">
            {previewUrl ? (
              accept.includes("image") ? (
                <img src={previewUrl} className="img-fluid img-thumbnail" />
              ) : previewUrl.endsWith("pdf") ? (
                <FontAwesomeIcon icon={faFilePdf} size="3x" />
              ) : previewUrl.endsWith("ppt") || previewUrl.endsWith("pptx") ? (
                <FontAwesomeIcon icon={faFilePowerpoint} size="3x" />
              ) : (
                <FontAwesomeIcon icon={faFile} size="3x" />
              )
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} size="2x" />
            )}

            <div className="figure-caption text-wrap">
              {previewUrl ? (
                <a href={previewUrl}>
                  {(selectedFile && selectedFile.name) ||
                    (origFileUrl &&
                      origFileUrl.split("/").pop().split("?")[0]) ||
                    ""}
                </a>
              ) : (
                "Ничего не выбрано"
              )}
            </div>
          </Col>
          <Col className="files col-12 col-md-9">
            <Input
              type="file"
              key={reset}
              className="form-control"
              accept={accept}
              onChange={onSelectFile}
            />
            <FontAwesomeIcon icon={faUpload} size="2x" />
            <div className="text-danger p-1 files-error" role="alert">
              {errorHint}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export async function updateFile(oldFile, newFile, setValue, requestBackend) {
  if (typeof newFile == "string") {
    if (newFile) {
      setValue(newFile);
    } else {
      await deleteFile(
        oldFile,
        () => setValue(""),
        (error) => console.error("error on file delete", error),
        requestBackend
      );
    }
  } else if (newFile) {
    await deleteFile(
      oldFile,
      () => {},
      (error) => console.error("error on file delete", error),
      requestBackend
    );
    await uploadFile(
      newFile,
      (responseData, textStatus, jqXHR) => setValue(responseData.url),
      (err) => {
        throw new Error(err);
      },
      requestBackend
    );
  }
}
