import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import { Header } from "./Header";
import { Footer } from "./Footer";

import "./Layout.css";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="layout">
        <NavMenu />
        <Header />
        <main>
          <Container className="container-fluid">
            <div className="my-5">{this.props.children}</div>
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
