import React from "react";
import { Link } from "react-router-dom";
import Routes, { MediaRoutes } from "../Routes";
import { EnrollerdUsersCount } from "./EnrolledUsersCount";
import { AuthorsInfoShort } from "./AuthorInfo";
import { MarkupedText } from "../_components/MarkupedText";
import { CourseActionLink, ProgramActionLink } from "./CatalogActions";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { ImagePlugged } from "../_components/ImagePlugged";
import { Mediacontent } from "../_components/Mediacontent";
import { getMoodleUrl } from "../_helpers/backend.url";

import "./ProgramPage.css";

const CourseItem = ({ course }) => {
  const courseRoute = Routes.getActual("Course", course.id, course.fullName);
  return (
    <div className="programpage-course position-relative">
      <div className="programpage-course-main">
        <Link to={courseRoute.route}>
          <h3>{courseRoute.title}</h3>
        </Link>
        <EnrollerdUsersCount count={course.enrolledUsersCount} />
        <AuthorsInfoShort authors={course.authors} />
        {course.shortDescription && (
          <div className="my-2">
            <MarkupedText description={course.shortDescription} />
          </div>
        )}
      </div>
      <div className="programpage-course-aside">
        <ImagePlugged
          src={course.imageUrl || getMoodleUrl(course.moodleImageUrl)}
          plugSrc={MediaRoutes.DefultCoursePoster}
          className="programpage-course-img"
          alt={course.fullName}
        />
        <CourseActionLink
          courseId={course.id}
          price={course.price}
          enrolled={course.enrolledByCurrentUser}
          width="100%"
        />
      </div>
    </div>
  );
};

export const ProgramDetails = ({ program }) => {
  const thisRoute = Routes.getActual("Program", program.id, program.fullName);

  return (
    <div className="position-relative">
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>

      <h1>{program.fullName}</h1>

      <EnrollerdUsersCount
        count={program.enrolledUsersCount}
        itemType={"Курс"}
      />
      <div className="my-3">
        <AuthorsInfoShort authors={program.authors} />
      </div>
      <ProgramActionLink
        programId={program.id}
        price={program.price}
        enrolled={program.enrolledByCurrentUser}
      />
      <h4>Аннотация</h4>
      <MarkupedText description={program.description} />

      <div>
        <h4>Состав курса</h4>
        {program.courses &&
          program.courses.map((course) => (
            <CourseItem key={course.id} course={course} />
          ))}
      </div>

      <Mediacontent
        presentationUrl={program.presentationUrl}
        presentationPreviewUrl={program.presentationPreviewUrl}
        videoUrl={program.videoUrl}
        videoPreviewUrl={program.videoPreviewUrl}
      />
    </div>
  );
};
