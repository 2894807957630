import React from 'react';
import { AccountPersonalInfo } from './AccountPersonalInfo';
import { AccountCourses } from './AccountCourses';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../_components/PrivateRoute';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import Routes from '../Routes';

export const AccountPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={Routes.Account.route}>{Routes.Account.title}</BreadcrumbsItem>
      <div className="m-4">
        <Switch>
          <PrivateRoute path={Routes.AccountCourses.route} component={AccountCourses} />
          <PrivateRoute path={Routes.Account.route} component={AccountPersonalInfo} />
        </Switch>
      </div>
    </div>
  );
}