import DefultCoursePoster from "./_img/default-poster.jpg";
import DefultPresentationPreview from "./_img/presentation-preview.png";
import DefultVideoPreview from "./_img/video-preview.png";

export const MediaRoutes = {
  DefultCoursePoster,
  DefultPresentationPreview,
  DefultVideoPreview,
};

const Routes = {
  Home: { route: "/", title: "Главная" },
  Logout: { route: "/logout", title: "Выйти" },

  Catalog: { route: "/catalog", title: "Каталог курсов" },
  Course: { route: "/catalog/course/:id", title: 'Лекция ":title"' },
  CourseAction: {
    route: "/catalog/course/:id/:action",
    title: 'Лекция ":title"',
  },
  Program: { route: "/catalog/program/:id", title: 'Курс ":title"' },
  ProgramAction: {
    route: "/catalog/program/:id/:action",
    title: 'Курс ":title"',
  },
  CourseBuy: { route: "/catalog/course/:id/buy", title: "Купить лекцию" },
  ProgramBuy: { route: "/catalog/program/:id/buy", title: "Купить курс" },
  CheckPayment: { route: "/catalog/checkpayment", title: "Проверка покупки" },

  Account: { route: "/account", title: "Настройки пользователя" },
  AccountCourses: { route: "/account/courses", title: "Мои лекции" },

  Admin: { route: "/admin", title: "Управление лекциями" },
  AdminLogin: { route: "/admin/login", title: "Вход в управление лекциями" },
  AdminHelp: {
    route: "/Marketplace_Administrator_manual.pdf",
    title: "Руководство по управлению лекциями",
  },
  EditCourse: {
    route: "/admin/course/:id",
    title: 'Настройки лекции ":title"',
  },
  CreateCourse: { route: "/admin/course/create", title: "Создание лекции" },
  EditProgram: {
    route: "/admin/program/:id",
    title: 'Настройки курса ":title"',
  },
  Authors: { route: "/admin/authors", title: "Управление авторами" },
  EditAuthor: {
    route: "/admin/authors/edit/:id",
    title: "Редактирование автора",
  },
  EditPublicOffer: {
    route: "/admin/publicoffer",
    title: "Редактирование публичной оферты",
  },

  VideoStream: { route: "/videostream", title: "Видеотрансляция" },

  getActual(routeName, id, title = "", params = null) {
    let actualRoute = {
      route: this[routeName].route.replace(":id", id),
      title: this[routeName].title.replace(":title", title),
    };
    if (params && params.length > 0) {
      const paramsStr = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      actualRoute.route += "?" + paramsStr;
    }
    return actualRoute;
  },
};

export default Routes;
