import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Route, Switch } from 'react-router-dom';
import { Catalog } from './Catalog';
import { CoursePage } from './CoursePage';
import { PrivateRoute } from '../_components/PrivateRoute';
import { ProgramPage } from './ProgamPage';
import Routes from '../Routes';

export const CatalogPage = () => (
  <>
    <BreadcrumbsItem to={Routes.Catalog.route}>{Routes.Catalog.title}</BreadcrumbsItem>
    <Switch>
      <PrivateRoute path={Routes.CourseAction.route} component={CoursePage} />
      <PrivateRoute path={Routes.ProgramAction.route} component={ProgramPage} />
      <Route path={Routes.Course.route} component={CoursePage} />
      <Route path={Routes.Program.route} component={ProgramPage} />
      <Route component={Catalog} />
    </Switch>
  </>
);