import React from "react";
import { Button, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { getMoodleCourseUrl } from "../_helpers/backend.url";
import { useAuthContext } from "../_services/authentication.service";
import { Role } from "../_helpers/role";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Routes from "../Routes";

export const MoodleCourseLink = ({ id, width = "auto" }) => {
  return (
    <a href={getMoodleCourseUrl(id)} style={{ width, margin: "0.5rem 0" }}>
      <Button className="btn-primary px-4" style={{ width }}>
        Перейти
      </Button>
    </a>
  );
};

export const BuyLink = ({ id, itemType, itemName, price, width = "auto" }) => {
  return (
    <Link
      to={Routes.getActual(itemType + "Buy", id).route}
      style={{ width, margin: "0.5rem 0" }}
    >
      <Button
        className={"btn-primary px-" + (price ? "5" : "3")}
        style={{ width }}
      >
        Купить {itemName}
        {price !== undefined && (
          <>
            {" "}
            за{" "}
            {price.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
              minimumFractionDigits: 0,
            })}
          </>
        )}
      </Button>
    </Link>
  );
};

export const EditLink = ({ id, itemType }) => {
  return (
    <Link
      to={Routes.getActual("Edit" + itemType, id).route}
      style={{ position: "absolute", right: "0.5rem", top: "0.25rem" }}
    >
      <FontAwesomeIcon
        icon={faCog}
        style={{ filter: "drop-shadow(0px 0px 5px var(--app-bg-color))" }}
      />
    </Link>
  );
};

export const CourseActionLink = ({
  courseId,
  price,
  enrolled,
  width = "auto",
}) => {
  const { user } = useAuthContext();
  //const user = getUser();
  const canedit =
    user && (user.role === Role.Admin || user.role === Role.Manager);
  return (
    <>
      {enrolled ? (
        <MoodleCourseLink className="m-1" id={courseId} width={width} />
      ) : (
        <BuyLink
          id={courseId}
          itemType="Course"
          itemName="лекцию"
          price={price}
          width={width}
        />
      )}
      {canedit && <EditLink className="m-1" itemType="Course" id={courseId} />}
    </>
  );
};

export const ProgramActionLink = ({
  programId,
  price,
  enrolled,
  width = "auto",
}) => {
  const { user } = useAuthContext();
  //const user = getUser();
  const canedit =
    user && (user.role === Role.Admin || user.role === Role.Manager);
  return (
    <>
      {!enrolled && (
        <BuyLink
          id={programId}
          itemType="Program"
          itemName="курс"
          price={price}
          width={width}
        />
      )}
      {canedit && (
        <EditLink className="m-1" itemType="Program" id={programId} />
      )}
    </>
  );
};
