import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Nav, NavbarText } from "reactstrap";
import { getRusNumbersEnding } from "../_helpers/FormatStrings";
import { doFilterSort } from "../_helpers/filter.sort";
import { Pagination } from "../_components/Pagination/Pagination";
import { SearchBar } from "../_components/SearchBar";
import { Loading } from "../_components/Loading";
import { FilterSelect, GetFilterOptions } from "../_components/Filter";
import { SortingOption } from "../_components/SortingOption";
import { CatalogItem } from "./CatalogItem";

const defaultItemsPerPage = 5;

const QueryParams = {
  groupByProgram: "group", // группировать ли в программы
  page: "page", // текущая страница
  itemsPerPage: "perpage", // строк на странице
  sortBy: "sort", // поле, по которому осуществляется сортировка
  sortDesc: "desc", // сортировка по убыванию?
  filters: "filter_", // префикс активных фильтров
};

const CatalogListPaginated = ({ list, emptyMessage, params, setParam }) => {
  const [itemsPerPage, setItemsPerPage] = useState(
    +params.get(QueryParams.itemsPerPage) || defaultItemsPerPage
  );
  const [currentPage, setCurrentPage] = useState(
    +params.get(QueryParams.page) || 0
  );

  function onPageChanged(currentPage, itemsPerPage) {
    setCurrentPage(currentPage);
    setItemsPerPage(itemsPerPage);
    setParam(QueryParams.page, currentPage);
    setParam(QueryParams.itemsPerPage, itemsPerPage);
  }

  const visibleCurrentPage =
    Math.min(currentPage, ~~(list.length / itemsPerPage)) || 0;
  const itemsOnPage =
    itemsPerPage > 0 && list
      ? list.slice(
          visibleCurrentPage * itemsPerPage,
          (visibleCurrentPage + 1) * itemsPerPage
        )
      : list;

  return (
    <Pagination
      itemsCount={list?.length}
      defaultItemsPerPage={itemsPerPage}
      defaultSelectedPage={currentPage}
      onPageChanged={onPageChanged}
    >
      <div className="text-label">
        Найдено {list?.length} лекц
        {getRusNumbersEnding(list?.length, "ия", "ии", "ий")} и курс
        {getRusNumbersEnding(list?.length, "", "а", "ов")}
      </div>
      {itemsOnPage && itemsOnPage.length > 0 ? (
        itemsOnPage.map((item) => <CatalogItem key={item.id} item={item} />)
      ) : (
        <em>
          {emptyMessage || "Лекции/курсы, соответствующие запросу, не найдены"}
        </em>
      )}
    </Pagination>
  );
};

export const CatalogList = ({
  title,
  loading,
  courses,
  programs,
  emptyMessage,
}) => {
  const history = useHistory();
  const location = useLocation();
  if (location.search) {
    window.sessionStorage.setItem(
      location.pathname + "-params",
      location.search
    );
  }
  const params = new URLSearchParams(
    window.sessionStorage.getItem(location.pathname + "-params") || ""
  );
  const [filteredCourses, setFilteredCourses] = React.useState(courses);

  function getFiltersFromParams() {
    var filterParams = {};
    params.forEach((value, key) => {
      if (key.startsWith(QueryParams.filters)) {
        filterParams = {
          ...filterParams,
          [key.substr(QueryParams.filters.length)]: { value, options: [] },
        };
      }
    });
    return filterParams;
  }
  const [filters, setFilters] = useState(getFiltersFromParams());
  const [sorting, setSorting] = useState(
    params.has(QueryParams.sortBy)
      ? {
          field: params.get(QueryParams.sortBy),
          descending: params.has(QueryParams.sortDesc),
        }
      : { field: "fullName", descending: false }
  );
  const [groupByProgram, setGroupByProgram] = useState(
    params.get(QueryParams.groupByProgram) || false
  );

  // сохранить значение параметра в URL и сессии браузера
  const setParam = (name, value) => {
    if (value) params.set(name, value);
    else params.delete(name);
    if (history.location.search !== "?" + params.toString()) {
      history.replace({
        search: "?" + params.toString(),
      });
    }
    window.sessionStorage.setItem(
      location.pathname + "-params",
      params.toString()
    );
  };

  // load filters list on courses update
  useEffect(() => {
    if (!courses && !programs) {
      return;
    }

    let filteredCourses = doFilterSort(courses, filters, sorting);
    setFilteredCourses(filteredCourses);

    let newFilters = {};
    newFilters["section"] = {
      value: filters?.section?.value ?? "",
      options: GetFilterOptions([...courses, ...programs], "section"),
    };
    newFilters["speciality"] = {
      value: filters?.speciality?.value ?? "",
      options: GetFilterOptions([...courses, ...programs], "speciality"),
    };
    newFilters["department"] = {
      value: filters?.department?.value ?? "",
      options: GetFilterOptions([...courses, ...programs], "department"),
    };
    newFilters["fullName"] = {
      value: filters?.fullName?.value ?? "",
      options: [],
    };
    setFilters(newFilters);
    setSortingValue(sorting.field, sorting.descending);
  }, [courses, programs]);

  const setFilterValue = (field, value) => {
    if (loading) return;
    let newFilters = Object.assign({}, filters);
    newFilters[field] = { ...newFilters[field], value };
    setFilters(newFilters);
    setParam(QueryParams.filters + field, value);
  };

  const setSortingValue = (field, descending) => {
    if (loading) return;
    if (descending === undefined) {
      descending = field === sorting.field && !sorting.descending;
    }
    setSorting({ field: field, descending: descending });
    setParam(QueryParams.sortBy, field);
    setParam(QueryParams.sortDesc, descending);
  };

  useEffect(() => {
    setParam(QueryParams.groupByProgram, groupByProgram);
  }, [groupByProgram]);

  let visibleList = [];
  if (!loading) {
    if (groupByProgram) {
      let filteredPrograms = doFilterSort(programs, filters, sorting);
      programs.forEach(
        (p) => (p.courses = doFilterSort(p.courses, {}, sorting))
      );
      const singleCourses = filteredCourses.filter(
        (course) =>
          !filteredPrograms.some((program) =>
            program.courseIds.includes(course.id)
          )
      );
      visibleList = [...filteredPrograms, ...singleCourses];
    } else {
      visibleList = filteredCourses || [];
    }
  }

  return (
    <>
      <Row>
        <Col className="col-lg-3">
          <FilterSelect
            label="Раздел"
            defaultSelectedOption={filters.section?.value ?? ""}
            options={filters.section?.options ?? []}
            onFilter={(value) => setFilterValue("section", value)}
          />
          <FilterSelect
            label="Специальность"
            defaultSelectedOption={filters.speciality?.value ?? ""}
            options={filters.speciality?.options ?? []}
            onFilter={(value) => setFilterValue("speciality", value)}
          />
          <FilterSelect
            label="Кафедра"
            defaultSelectedOption={filters.department?.value ?? ""}
            options={filters.department?.options ?? []}
            onFilter={(value) => setFilterValue("department", value)}
          />
          <div className="form-check">
            <input
              type="checkbox"
              id="check-groupbyprogram"
              className="form-check-input"
              checked={groupByProgram}
              onChange={() => setGroupByProgram(!groupByProgram)}
            />
            <label className="form-check-label" htmlFor="check-groupbyprogram">
              Группировать в курсы
            </label>
          </div>
        </Col>
        <Col className="col-lg-9">
          <Row>
            <Col>
              <h1>{title}</h1>
              <Row>
                <Col>
                  <Nav className="nav-pills">
                    <NavbarText className="mx-1 text-label">
                      Сортировать по
                    </NavbarText>
                    <SortingOption
                      text="Стоимости"
                      active={sorting.field === "price"}
                      descending={
                        sorting.field === "price" && sorting.descending
                      }
                      onSort={() => setSortingValue("price")}
                    />
                    <SortingOption
                      text="Названию"
                      active={sorting.field === "fullName"}
                      descending={
                        sorting.field === "fullName" && sorting.descending
                      }
                      onSort={() => setSortingValue("fullName")}
                    />
                  </Nav>
                </Col>
                <Col>
                  <SearchBar
                    placeholder="Поиск по выбранным лекциям..."
                    defaultValue={filters.fullName?.value ?? ""}
                    onSearch={(value) => setFilterValue("fullName", value)}
                  />
                </Col>
              </Row>
              {loading ? (
                <Loading />
              ) : visibleList ? (
                <CatalogListPaginated
                  list={visibleList}
                  emptyMessage={emptyMessage}
                  params={params}
                  setParam={setParam}
                />
              ) : (
                <div>Ничего не найдено</div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
