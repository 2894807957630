import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

export const ImagePlugged = ({ src, plugSrc, plugIcon, ...props }) => {
  const [actualSrc, setActualSrc] = useState(src || plugSrc);
  return (
    <>
      {
        actualSrc ?
          <img
            src={actualSrc}
            style={{ objectFit: "cover" }}
            {...props}
            onError={() => { console.warn(`image ${actualSrc} not found`); setActualSrc(plugSrc !== actualSrc ? plugSrc : '') }}
          />
          :
          <div {...props} style={{ display: "flex", padding: "10%", color: "var(--hr-color)" }}>
            <FontAwesomeIcon icon={plugIcon || faBan} size="3x" />
          </div>
      }
    </>
  );
}