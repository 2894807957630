import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useAuthContext } from "../_services/authentication.service";
import { CatalogList } from "../Catalog/CatalogList";

export const AccountCourses = () => {
  const { user, requestBackend } = useAuthContext();
  //const user = getUser();
  const [courses, setCourses] = useState([]);
  const [programCourses, setProgramCourses] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);

  // load courses and programs  of the user
  useEffect(() => {
    $.when(
      requestBackend({
        url: `/users/${user.id}/courses`,
        onSuccess: (data) => {
          setCourses(data);
        },
      }),
      requestBackend({
        url: `/users/${user.id}/programs`,
        onSuccess: (data) => {
          setPrograms(data);
          setProgramCourses(
            data.reduce(
              (allC, p) => [
                ...allC,
                ...p.courses.filter((c) => !allC.some((ac) => ac.id === c.id)),
              ],
              []
            )
          );
        },
      })
    ).then(function () {
      setLoading(false);
    });
  }, [user, requestBackend]);

  return (
    <CatalogList
      title="Мои лекции"
      loading={loading}
      courses={[
        ...courses,
        ...programCourses.filter((pc) => !courses.some((c) => c.id === pc.id)),
      ]}
      programs={programs}
      emptyMessage="Лекции не найдены"
    />
  );
};
