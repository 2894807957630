import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Routes from "../Routes";
import { useAuthContext } from "../_services/authentication.service";
import { Loading } from "./Loading";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { user, loginSso } = useAuthContext();
  const [keycloak] = useKeycloak();
  const [isRedirecting, setisRedirecting] = useState(false);

  useEffect(() => {
    if (!isRedirecting) {
      setTimeout(() => {
        if (!keycloak.authenticated && !user) {
          setisRedirecting(true);
          loginSso();
        }
      }, 1000);
    }
  }, [isRedirecting, keycloak.authenticated, loginSso, user]);

  //const user = getUser();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <>
              Выполняется вход...
              <Loading />
            </>
          );
        }

        if (roles && user.role && roles.indexOf(user.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: Routes.Home.route }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};
