import React from 'react';

const videoUrl =
  (window.app && window.app.env.STREAM_URL) ||
  process.env.STREAM_URL;

export const EmbeddedVideo = () => {
    return (
        <div style={{height: "100%", width: "100%;"}}>
            {videoUrl ?
                <iframe 
                    width="100%" 
                    height="100%" 
                    src={videoUrl} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                </iframe>
            : 'Ссылка на видео не найдена в настройках.'
            }
        </div>
    );
};
