import React, { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useAuthContext } from "../_services/authentication.service";
import Routes from "../Routes";
import { AuthorForm } from "./AuthorForm";
import { Loading } from "../_components/Loading";
import { NotFoundPage } from "../Errors/NotFoundPage";

export const EditAuthor = ({ match }) => {
  const authorId = match.params.id;
  const { requestBackend } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [author, setAuthor] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    requestBackend({
      url: `/users/${authorId}`,
      onSuccess: (data) => {
        setAuthor(data);
      },
    }).finally(() => setLoading(false));
  }, [authorId, requestBackend]);

  if (loading) {
    return <Loading />;
  }

  if (!author) {
    return <NotFoundPage />;
  }

  const thisRoute = Routes.getActual("EditAuthor", authorId, author.fullName);

  return (
    <>
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>
      <h2>Редактирование информации об авторе</h2>
      <AuthorForm author={author} />
    </>
  );
};
