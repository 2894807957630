import React from "react";
import { Card, CardImg, CardBody } from "reactstrap";
import { abbreviatedName } from "../_helpers/FormatStrings";
import { getMoodleUrl } from "../_helpers/backend.url";

import "./AuthorInfo.css";

const AuthorInfoShort = ({ author }) => (
  <span key={author.id} className="authorshort">
    <strong>{abbreviatedName(author.fullName)}</strong>{" "}
    {author.description && author.description.replace(/<[^>]*>/g, "")}
  </span>
);

export const AuthorsInfoShort = ({ authors }) => {
  if (!authors?.length) return <></>;
  if (authors.length === 1) return <AuthorInfoShort author={authors[0]} />;
  return (
    <>
      <strong>Авторы:</strong>
      {authors.map((author) => (
        <AuthorInfoShort author={author} key={"author-" + author} />
      ))}
    </>
  );
};

// TODO change link on back
export const AuthorInfo = ({ author }) =>
  author && (
    <Card
      kay={author.id}
      className="border-light my-3 p-1"
      style={{ backgroundColor: "transparent", fontSize: "80%" }}
    >
      <CardImg
        src={getMoodleUrl(author.profileImageUrl.replace("/f1", "/f3"))}
      />
      <CardBody className="p-1 mt-3">
        <strong>Автор лекции: {abbreviatedName(author.fullName)}</strong>
        {author.description && (
          <span dangerouslySetInnerHTML={{ __html: author.description }} />
        )}
      </CardBody>
    </Card>
  );
