import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Routes from "../Routes";
import { Loading } from "../_components/Loading";
import { useAuthContext } from "../_services/authentication.service";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { getMoodleUrl } from "../_helpers/backend.url";
import Table from "reactstrap/lib/Table";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { SearchBar } from "../_components/SearchBar";
import { Tooltip } from "reactstrap";
import { getRusNumbersEnding } from "../_helpers/FormatStrings";
import Alert from "reactstrap/lib/Alert";

const AuthorsTable = ({ authors }) => {
  if (!authors) {
    return <Alert color="danger">Не удалось загрузить список авторов</Alert>;
  }
  return (
    <Table>
      <tbody>
        {authors.map((author) => (
          <tr>
            <td style={{ width: "3em" }}>
              <img
                src={getMoodleUrl(author.profileImageUrl.replace("/f1", "/f3"))}
                style={{ height: "2em" }}
              />
            </td>
            <td>{author.fullName}</td>
            <td>{author.email}</td>
            <td style={{ width: "1.5em" }}>
              <Link to={Routes.getActual("EditAuthor", author.id).route}>
                <FontAwesomeIcon icon={faCog} />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const Authors = () => {
  const { requestBackend } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [authors, setAuthors] = useState(undefined);
  const [search, setSearch] = useState(undefined);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    requestBackend({
      url: `/users/pauthors`,
      onSuccess: (data) => {
        setAuthors(data);
      },
    }).finally(() => setLoading(false));
  }, [requestBackend]);

  if (loading) {
    return <Loading />;
  }

  const visibleAuthors = search
    ? authors.filter(
        (a) =>
          a.fullName.toLowerCase().includes(search) ||
          a.email.toLowerCase().includes(search)
      )
    : authors;

  return (
    <>
      <BreadcrumbsItem to={Routes.Authors.route}>
        {Routes.Authors.title}
      </BreadcrumbsItem>
      <h1>
        Управление авторами{" "}
        <span href="#" id="TooltipInfo">
          <FontAwesomeIcon icon={faInfoCircle} size="sm" />
        </span>
        <Tooltip
          placement="right-start"
          className="text-alignment-left"
          isOpen={tooltipOpen}
          target="TooltipInfo"
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          Данный список содержит пользователей, которые обладают правами
          авторов. Если преподаватель был назначен в отдельной лекции вручную,
          он не отобразится здесь.
        </Tooltip>
      </h1>
      <Row>
        <Col></Col>
        <Col xs={4}>
          <SearchBar
            placeholder="Поиск по авторам"
            onSearch={(value) => {
              setSearch(value.toLowerCase());
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-label">
            Найдено {authors?.length} автор
            {getRusNumbersEnding(authors?.length, "", "а", "ов")}
          </div>
          <AuthorsTable authors={visibleAuthors} />
        </Col>
      </Row>
    </>
  );
};

Authors.propTypes = {};
