import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../_components/Loading";
import { useAuthContext } from "../_services/authentication.service";
import { BuyCourse } from "./BuyCourse";
import Routes from "../Routes";
import { CourseDetails } from "./CourseDetails";

export const CoursePage = (props) => {
  const id = props.match.params.id;
  const action = props.match.params.action;
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user, requestBackend } = useAuthContext();

  useEffect(() => {
    function populateCourseData(courseId, user) {
      requestBackend({
        url: `/courses/${courseId}` + (user ? `?currentUserId=${user.id}` : ""),
        onSuccess: (data) => {
          setCourse(data);
          setLoading(false);
        },
      });
    }
    setLoading(true);
    populateCourseData(id, user);
  }, [id, user, requestBackend]);

  return (
    <div style={{ marginTop: "1.5rem" }}>
      {loading ? (
        <Loading />
      ) : !course ? (
        <div>
          Лекция не найдена{" "}
          <Link to={Routes.Catalog.route}>Вернуться в каталог</Link>
        </div>
      ) : action === "buy" ? (
        <BuyCourse course={course} location={props.location} />
      ) : (
        <CourseDetails course={course} />
      )}
    </div>
  );
};
